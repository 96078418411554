/** @jsx jsx */

import React from 'react'
import { jsx } from 'theme-ui'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div sx={{
      textAlign: 'center'
    }}>
      <h1>404</h1>
    </div>
  </Layout>
)

export default NotFoundPage
